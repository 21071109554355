import React, { ReactNode } from 'react'
import { Link, navigate } from 'gatsby'
import { LinkProvider, MediaSizeProvider } from '@bestyled/contrib-common'
import themeSlipway from '../src/styles/theme'

import { createElement } from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const cwdTheme = require('@content/theme/index').default
import _ from 'lodash'
import { AppWrapper, useAppContext } from '../src/utils/global-context';

interface GatsbyRootProps {
  element: ReactNode
}

const theme = _.merge(themeSlipway, cwdTheme)

export const wrapRootElement = (
  gatsbyRootProps: GatsbyRootProps
  // pluginOptions: { uitheme?: any }
) => {
  _.extend(theme, cwdTheme)
  // @ts-ignore

  // Insert React context helpers here
  return (
    <AppWrapper>
      <StyledThemeProvider theme={theme}>
        <LinkProvider Link={Link} navigate={navigate}>
          <MediaSizeProvider>{gatsbyRootProps.element}</MediaSizeProvider>
        </LinkProvider>
      </StyledThemeProvider>
    </AppWrapper>
  )
}

export const StyledThemeProvider = ({ children, theme }) => {
  // @ts-ignore
  const [context = {theme: null}] = useAppContext()
  const {theme: theme2} = context
  return (
    <ThemeProvider theme={!theme2 ? theme : theme2}>
      {children}
      <BodyStyle key='theme-body' />
    </ThemeProvider>
  )
}

const BodyStyle = createGlobalStyle<{ theme: any }>`
body {
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.background};
}
`
